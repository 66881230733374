<template>
    <div>
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true }"></v-header>
        </div>
        <div class="contents">
            <van-tabs v-model="active" :animated="true">
                <van-tab title="排列五">
                    <div>
                        <button @click="gotolist(0)">芝麻</button>
                        <button @click="gotolist(1)">三定</button>
                    </div>
                    <div>
                        <button @click="gotolist(2)">杀数</button>
                        <button @click="gotolist(3)">合数</button>
                    </div>
                </van-tab>
                <van-tab title="七星彩">
                    <div>
                        <button @click="gotolist(0)">芝麻</button>
                        <button @click="gotolist(1)">三定</button>
                    </div>
                    <div>
                        <button @click="gotolist(2)">杀数</button>
                        <button @click="gotolist(3)">合数</button>
                    </div>
                </van-tab>
                
            </van-tabs>
        </div>
    </div>
</template>

<script>
import vHeader from '@/components/header.vue'

export default {
    components:{
        vHeader
    },
    data(){
        return{
            title: this.$route.meta.title,
            active:0,
            // buttonlist:['芝麻','三定','杀数','合数'],
        }
    },
    methods:{
        gotolist(n){
            // console.log(n)
            // console.log(this.active===0?5:7)
            this.$router.push({
                name: 'masterrankinglist',
                query:{
                    type: n,
                    lotteryKind: this.active === 0 ? 5 : 7,
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.contents{
    button{
        font-size: 0.4rem;
        width: 4rem;
        height: 1.3rem;
        border: none;
        border-radius: 7px;
        margin: 0.3rem 0.5rem;
        background-color: red;
        color: white;
    }
}
</style>